<template>
  <div class="poster">
    <div class="breadcrumb-m">
      <div class="left">
        <Breadcrumb>
          <BreadcrumbItem to="/">主控台</BreadcrumbItem>
          <BreadcrumbItem>配置管理</BreadcrumbItem>
          <BreadcrumbItem>海报设置</BreadcrumbItem>
        </Breadcrumb>
      </div>
      <div class="right">
        <Button type="info" icon="ios-add" @click="opentags('/posteredit',{id:0})">新增海报</Button>
      </div>
    </div>
    <Table size="small" :columns="columns" :data="data.data" :loading="table_loading">
      <template slot-scope="row" slot="bgimg"><img class="bgimg" :src="row.row.bgimg"/></template>
      <template slot-scope="row" slot="status">
        <i-switch size="large" v-model="data.data[row.index].status" :true-value="1" :false-value="0" :loading="data.data[row.index].loading" @on-change="changestatus($event,row.index)">
          <span slot="open">启用</span>
          <span slot="close">停用</span>
        </i-switch>
      </template>
      <template slot-scope="row" slot="isdefault">
          <i-switch size="large" v-model="data.data[row.index].isdefault" :true-value="1" :false-value="0" :loading="data.data[row.index].loading2" @on-change="changedefault($event,row.index)">
            <span slot="open">是</span>
            <span slot="close">否</span>
          </i-switch>
      </template>
      <template slot-scope="row" slot="action">
        <Button type="primary" size="small" @click="opentags('/posteredit',{id:row.row.id})">编辑</Button>
        <Button type="error" size="small" style="margin-left: 10px;" @click="del(row.row.id)">删除</Button>
      </template>
    </Table>
    <div class="footer-m">
      <Page class-name="pagew page-r" :total="data.total" :current="data.current_page" :page-size="data.per_page" :page-size-opts="[5,15,50]" show-sizer show-total @on-change="page" @on-page-size-change="change_pagesize"></Page>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Poster',
  data(){
    return {
      columns:[
        {title: 'ID',key: 'id',minWidth:100,maxWidth:100},
        {title: '海报背景',slot: 'bgimg',minWidth:200,maxWidth: 300},
        {title: '海报状态',slot: 'status',minWidth:150},
        {title: '是否默认',slot: 'isdefault',minWidth:150},
        {title: '更新时间',key: 're_time',minWidth:200},
        {title: '操作',slot: 'action',width:160},
      ],
      data:{
        data:[]
      },
      posdata:{
        page_size:15,
        page:1
      },
      table_loading:false
    }
  },
  activated() {
    this.init();
  },
  methods:{
    init:function (){
      var _this=this;
      this.table_loading=true;
      this.requestApi('/adm/poster_list.html',this.posdata).then(function (res){
        _this.table_loading=false;
        if(res.status==1) _this.data=res.data;
      })
    },
    /**
     * 状态切换
     */
    changestatus:function (status,key){
      var _this=this,id=this.data.data[key].id;
      this.$set(_this.data.data[key],'loading',true);
      this.requestApi('/adm/poster_status.html',{'id':id,'status':status}).then(function (res){
        _this.data.data[key].loading=false;
        if(res.status!=1){
          _this.$set(_this.data.data[key],'status',status?0:1);
          _this.alertError(res.msg);
        }else{
          _this.init();
        }
      })
    },
    /**
     * 默认切换
     */
    changedefault:function (status,key){
      var _this=this,id=this.data.data[key].id;
      this.$set(_this.data.data[key],'loading2',true);
      this.requestApi('/adm/poster_default.html',{'id':id,'isdefault':status}).then(function (res){
        _this.data.data[key].loading2=false;
        if(res.status!=1){
          _this.$set(_this.data.data[key],'isdefault',status?0:1);
          _this.alertError(res.msg);
        }else{
          _this.init();
        }
      })
    },
    /**
     * 删除海报
     */
    del:function (id){
      var _this=this;
      this.confirm('确定要删除吗？',function (){
        _this.requestApi('/adm/del_poster.html', {'id':id}).then(function (res){
          if(res.status==1){
            _this.init();
            _this.tipSuccess(res.msg);
          }else{
            _this.alertError(res.msg);
          }
        })
      })
    },
    /**
     * 翻页
     */
    page:function (page){
      this.posdata.page=page;
      this.init();
    },
    /**
     * 切换分页条数
     */
    change_pagesize:function (size){
      this.posdata.page_size=size;
      if(this.posdata.page==1) this.init();
    }
  }
}
</script>
<style lang="less">
@import "~@/assets/css/sysset/poster";
</style>